<template>
  <div>
    <Section1 :keyWord="'La mejor solución para un crédito aval coche Las Palmas'" />
    <div class="texto-landing">
      <!-- Escribe aquí gaston, gracias :) -->
      <h2 class="title-h1 text-center pb-4"><strong>Crédito aval coche Las Palmas</strong></h2>

      <h3 class="naranja"><strong>Crédito aval coche Las Palmas</strong></h3>
      <p class="">¿Vives en Las Palmas de Gran Canaria y necesitas liquidez de manera urgente? Estás de enhorabuena. Gracias al servicio <strong>crédito aval coche Las Palmas</strong> podrás contar con tu dinero en menos de 24 horas completando todo el proceso de manera 100% online sin necesidad de moverte de casa.</p>
      <p class="">La tramitación es muy sencilla. Solo tienes que completar un formulario, elegir la cantidad que necesitas y comenzar a disfrutar de tu dinero. ¿Quieres conocer todos los detalles del <strong>crédito aval coche Las Palmas</strong>? ¡Adelante!</p>


      <h3 class="naranja"><strong>¿Cómo funciona el crédito aval coche Las Palmas?</strong></h3>
      <p class="">¿Has intentado pedir alguna vez un crédito a una entidad bancaria? Requisitos imposibles de cumplir, procesos que se eternizan, firmas, avales…</p>
      <p class="">El servicio de <strong>crédito aval coche Las Palmas</strong> es un método moderno e innovador que ofrece a sus clientes la posibilidad de resolver cualquier problema económico con rapidez y solvencia ahorrándose el estrés y la incertidumbre de los sistemas de crédito tradicionales.</p>
      <p class="">El único requisito es poner tu coche como aval. Pero no creas que esto significa que pierdas tu titularidad o que se te retire. Podrás seguir conduciendo sin necesidad de entregar siquiera la documentación original ni copias de las llaves.El coche siempre será tuyo. </p>

      <h3 class="naranja"><strong>Quiero empezar con mi crédito aval coche Las Palmas. ¿Qué tengo que hacer?</strong></h3>
      <p class="">Si no quieres esperar ni un minuto más para tener en tu cuenta bancaria el dinero que necesitas, lo primero que tienes que hacer es completar el formulario del <strong>crédito aval coche Las Palmas</strong> indicando la matrícula del vehículo y tus datos personales.</p>
      <p class="destacar">¿Sabías que el <strong>crédito aval coche Las Palmas</strong> también acepta personas incluidas en las listas de la ASNEF o de la RAI?</p>
      <p class="">Una vez enviado el formulario, recibirás en tu correo electrónico una propuesta de préstamo ajustada a tus necesidades de manera totalmente gratuita.</p>
      <p class="">Si la aceptas, tan solo tendrás que enviar la documentación adicional requerida y en un plazo de 24 horas podrás empezar a disponer de tu dinero. ¡Tenemos el Récord de 2 horas en enviar el dinero!</p>


      <h3 class="naranja"><strong>Cuáles son los beneficios del crédito aval coche Las Palmas</strong></h3>
      <p class="">Los clientes del servicio de <strong>crédito aval coche Las Palmas</strong> cuentan con soporte y asesoramiento continuo de un gestor personal para resolver cualquier duda en el proceso de formalización.</p>
      <p class="">Además de este servicio de atención al cliente, al solicitar alguno de los créditos disponibles, puedes acceder a beneficios exclusivos como:</p>

      <ul class="">
        <li>Hasta 6000 euros de crédito en menos de 24 horas</li>
        <li>Tramitación 100% online.</li>
        <li>Sin cambio de titularidad ni retirada del vehículo.</li>
        <li>Amortización del préstamo desde la primera cuota.</li>
        <li>Intereses mucho más bajos que los de un minicrédito.</li>
        <li>Sin renting ni alquileres para seguir disfrutando de tu vehículo.</li>
        <li>Pack de servicios exclusivos que incluye una revisión técnica anual de mantenimiento e informes de tráfico para asegurar que el coche sigue siendo tuyo.</li>
      </ul>

      <h3 class="naranja"><strong>El mejor crédito aval coche Las Palmas</strong></h3>
      <p class="">Más de 5 años de experiencia y más de 2.000 clientes satisfechos. El servicio de <strong>crédito aval coche Las Palmas</strong> se distingue de la competencia por poner el foco en el bienestar y la tranquilidad de sus clientes.</p>
      <p class="">Sin renting, sin retirada de vehículo y sin cuotas extras. Rápido, seguro y 100% online. Así es el mejor servicio de <strong>crédito aval coche Las Palmas.</strong></p>

    </div>
    <Section2 />
    <CreditoCocheS4 /> <!-- acordeón de preguntas -->
    <div class="relleno"></div>
    
    <div class="cards-prestamo">
      <div class="d-flex flex-row justify-content-center align-items-center flex-wrap pt-2">
        <div class="padding">
            <h2 class="title-h1 text-center">Préstamo por tu coche</h2>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
          <div class="card m-4"  >
            <a href="../prestamo-por-tu-coche" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamo por tu coche</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Entra aquí y conoce todo lo que debes saber para conseguir un préstamo por tu coche y resolver tus problemas.</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../credito-por-tu-coche" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito por tu coche</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue un crédito por tu coche. Crédito por tu coche Online</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../dinero-rapido-con-tu-coche" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Dinero rápido con tu coche</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero fácil con tu coche. Préstamo rápido y fácil por tu coche. Préstamos online por tu coche.</p>
              </div>
            </a>
          </div>
      </div>
      <!--  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../prestamos-con-asnef-y-nomina-rapidos" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos con Asnef y nómina rápidos</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Ya puedes conseguir préstamos con Asnef y nómina de manera muy sencilla.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-con-aval-de-coche-sin-cambiar-de-titular" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos con aval de coche sin cambiar de titular</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche sin cambiar la titularidad.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-con-aval-del-coche-de-mas-de-10-anos" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos con aval del coche de mas de 10 años</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Tenemos toda la información que necesitas leer sobre esto.</p>
              </div>
            </a>
          </div>
      </div> 
<!-- fila landing 5  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../credito-aval-coche-con-asnef" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito aval coche con Asnef</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche estando en Asnef.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../credito-coche" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito con aval</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Tenemos la solución para un crédito por tu coche con aval.</p>
              </div>
            </a>
          </div>
      </div>

      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column p-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>

    </div>
    <div class="relleno"></div>
    <!-- No te pases de aquí gaston, gracias 2.0 :) -->
  </div>
</template>

<script>
// @ is an alias to /src
import Section1 from '@/components/Home/Section1.vue'
import { useMeta } from 'vue-meta'
import store from "@/store";
import Section2 from '@/components/Home/Section2.vue'
import CreditoCocheS4 from '@/components/CreditoCoche/CreditoCocheS4.vue'

// @ is an alias to /src
export default {
  name: 'Home',
  components: {
    Section1,
    Section2,
    CreditoCocheS4
  },
  setup() {

    useMeta({
      title: 'Crédito aval coche Las Palmas',
      meta:[
        {name:'robots', content:store.state.dominio == 'ibancar.com' ? 'index' : 'noindex'},
        {name:'description', content:'Consigue crédito por tu coche en Las Palmas ¿Necesitas conseguir dinero rápido con tu coche? Entra aquí, te explicamos con lujos y detalles todo lo que necesitas saber para adquirir una financiación.'},
        {name:'keywords', content:'Dinero rápido con tu coche, Préstamo rápido y fácil, Conseguir dinero fácil.'}
      ]
    })
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
.naranja {
  color: $orange;
  font-weight: bold;
}
.texto-landing {
  padding: 3em 15em;
  line-height: 2em;
}
.card {
  width: 22rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 10rem;
}
.inline {
  display: inline;
}
.caret-sign, .caret-sign-city {
  margin-left: 25px;
  vertical-align: top;
  float: right;
}
.caret-sign-city {
  position: relative;
  top: -27px;
}
a.no {
  text-decoration: none;
  color: #333;
}
a.no:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  bottom: 1px;
  height: 10rem;
}
.relleno {
  height: 50px;
}

p.destacar {
    margin: 24px 0px;
    font-size: 1.2em;
    background-color: #FDF5E6;
    padding: 30px;
}

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs,sm) {
  .texto-landing {
    padding: 1.5em 2em;
  }
  .card {
    height: 100%;
  }
  a.no:hover {
    height: 100%;
  }
  .title-h1 {
    font-size: 32px;
  }
  .naranja {
    font-size: 24px;
  }
}

@include media-breakpoint-between(md,lg) {
  .texto-landing {
    padding: 1.5em 5em;
  }
}

@include media-breakpoint-between(xl,xxl) {

}
</style>